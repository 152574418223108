import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252,replace 0,1,2) should always be at the top of the file.
   * AUSDT  variable is busd(BUSD) and WCORE variable is wbnb (CORE,WCORE)
   */
  {
    pid: 0,
    lpSymbol: 'PIPILOL',
    lpAddresses: {
      5438: '0xa46e3b4a28ed18d72d5d451db9688eebd4fa1176',
      1116: '0xa46e3b4a28ed18d72d5d451db9688eebd4fa1176',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
 
  {
    pid: 1,
    lpSymbol: 'PIPILOL-CORE LP',
    lpAddresses: {
      5438: '',
      1116: '0xb43E17b52122e1c6E81482Ca072844982C179033',
    },
    token: tokens.pipilol,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 2,
    lpSymbol: 'USDT-CORE LP',
    lpAddresses: {
      5438: '',
      1116: '0x45d528cd746d7bb44f8028c37ccac0608fa02751',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  
  
    
    
  	  

]

export default farms
